<template>
  <div class="container">
    <div class="mobile" v-if="!flag">
      <header>
        <img src="@/static/images/ic_logo_bottom@2x.png" alt="" />
      </header>
      <div class="menus">
        <span @click="$router.push('/')">首页</span>&nbsp;> 公开信息披露 >
        偿付能力信息
      </div>
      <div class="main-content">
        <van-list
          v-model="loading"
          finished-text="没有更多了"
          class="list"
          @load="onLoad"
          offset="0"
          :finished="finished"
          :immediate-check="false"
        >
          <div class="time">最新信息披露日期：{{ data }}</div>
          <div v-html="contentContent" class="text-content">
            中德安联人寿保险有限公司2022年第3季度的综合偿付能力充足率为267.81%，达到监管要求且最近季度风险综合评级结果为AA。公司过往季度的上述相关信息，请见以下偿付能力季度报告摘要。
          </div>
          <div class="search" style="display: flex; justify-content: flex-end">
            <div class="search-wrap">
              <el-input
                clearable
                placeholder="输入关键字搜索"
                v-model="searchVal"
              />
              <img @click="submit" src="~@/static/images/search.png" alt="" />
            </div>
          </div>
          <div class="item" v-for="(item, index) in matters" :key="index">
            <a class="title" :href="item.fileUrl" target="_blank">{{
              item.fileTitle
            }}</a>
            <div class="check-btn">
              <a :href="item.fileUrl" target="_blank"> 查看 </a>
            </div>
          </div>
        </van-list>
      </div>
      <div class="bottom">
        <div class="qrcode">
          <div class="item">
            <img src="~@/static/images/public.jpg" alt="" />
            <span>公众号：安联人寿</span>
          </div>
          <div class="item">
            <img src="~@/static/images/service.jpg" alt="" />
            <span>服务号-安联在线</span>
          </div>
        </div>
        <div class="info">
          <p>© 安联人寿保险有限公司2012.All Rights Reserved.</p>
          <p class="beian">沪ICP备07011309号-1</p>
        </div>
      </div>
    </div>
    <div class="solvencyCapacity" v-else>
      <crumbs :item="title"></crumbs>
      <main class="box">
        <div class="time">最新信息披露日期:{{ data }}</div>
        <Editor :readonly="true" :content="contentContent" :height="0"></Editor>
        <div class="main" v-if="total">
          <div class="box">
            <!--        <download :matters="matters"></download>-->
            <div class="matters">
              <ul>
                <li v-for="(item, index) in matters" :key="index">
                  <a :href="item.fileUrl" target="_blank">
                    <p class="matters_title">
                      {{ item.fileTitle }}
                    </p>
                  </a>
                  <div>
                    <p>{{ item.fileDate }}</p>
                    <p>
                      <span>文件格式:{{ item.fileType }}</span>
                    </p>
                    <p>
                      <span class="matters_time"
                        >文件大小:{{ tansFileSize(item.fileSize) }}</span
                      >
                    </p>
                  </div>
                  <div class="matters_download">
                    <a :href="item.fileUrl" target="_blank">
                      <img
                        src="@/static/images/secondaryMenu/disclosure/xiazai@2x.png"
                        alt=""
                      />
                      <p>下载</p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div class="paging">
              <!-- <p v-if="tab == 1">共有{{ data.length }}条记录</p> -->
              <p>共有{{ total }}条记录</p>
              <div>
                <img
                  @click="getPageNumAReduction()"
                  src="~@/static/images/l_arrow@2x.png"
                  alt=""
                />
                <span>{{ pageNum }}/{{ pages }}</span>
                <img
                  @click="getPageNumAdd()"
                  src="~@/static/images/ic_arrow_black@2x.png"
                  alt=""
                />
              </div>
            </div>
            <!--          <Paging class="page" :total="total" :pageNum="pageNum" :pages="pages"></Paging>-->
          </div>
        </div>
        <div class="main" v-else>
          <empty></empty>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
// import Paging from "@/components/paging.vue";
import empty from "@/components/empty";
import Editor from "@/components/TinyMce";
import { getFileList, getPublishDate } from "@/api/public";
import { getContentInfo } from "@/api/disclosure";
import common from '@/utils/common'
import $ from "jquery";

export default {
  name: "solvencyCapacity",
  data() {
    return {
      title: [
        {
          title: "公开信息披露",
          link: "",
        },
        {
          title: "偿付能力信息",
          link: "/solvencyCapacity/AZ01005",
        },
      ],
      matters: [],
      total: 0,
      pageNum: 0,
      pages: 0,
      contentContent: null,
      data: "",
      flag: undefined,
      searchVal: "",
      loading: false,
      finished: false,
    };
  },

  components: { crumbs, empty, Editor },
  created() {
    this.isPC();
    this.requestText();
    this.request();
    this.publishDate();
  },
  mounted() {
    this.selfAdaption();
  },
  methods: {
    submit() {
      this.pageNum = 1;
      this.loading = true;
      this.matters = [];
      this.finished = false;
      this.request(this.pageNum);
    },
    onLoad() {
      console.log("bottom");
      this.pageNum = this.pageNum + 1;
      this.request(this.pageNum);
    },
    selfAdaption: function selfAdaption() {
      let _this = this;
      setTimeout(function () {
        window.onresize = () => {
          return (() => {
            _this.$nextTick(() => {
              _this.isPC();
            });
          })();
        };
      }, 200);
    },
    isPC() {
      this.flag = window.navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      var isPad = common.isPad();
			if(isPad){
				this.flag = false
			}
      this.flag = !this.flag;
      console.log(this.flag);
      if (!this.flag) {
        $("body").css("overflow", "hidden");
      }
    },
    // 下一页
    getPageNumAdd() {
      if (this.pageNum < this.pages) {
        this.pageNum = this.pageNum + 1;
        this.request(this.pageNum);
      } else {
        this.$message.warning("已经是最后一页了");
        console.log("no");
      }
    },
    // 上一页
    getPageNumAReduction() {
      if (this.pageNum <= 1) {
        this.$message.warning("已经是第一页了");
      } else {
        this.pageNum = this.pageNum - 1;
        this.request(this.pageNum);
      }
    },
    tansFileSize(size) {
      var data = "";
      if (size < 0.1 * 1024 * 1024 * 1024) {
        //如果小于0.1GB转化成MB
        data = (size / (1024 * 1024)).toFixed(2) + "MB";
      } else {
        //其他转化成GB
        data = (size / (1024 * 1024 * 1024)).toFixed(2) + "GB";
      }
      let sizestr = data + "";
      return sizestr;
    },
    getStr(string, str) {
      let str_before = string.split(str)[0];
      return str_before;
    },
    change(e) {
      console.log(e);
    },
    async requestText() {
      // this.fullscreenLoading = true;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getContentInfo({
          timeStamp,
          sign,
          contentType: this.$route.params.id,
        });
        console.log("文本", res.data);
        if (res.data.code == 200) {
          console.log(res.data);
          this.contentContent = res.data.data.contentContent;
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
    async request(num) {
      // this.fullscreenLoading = true;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getFileList({
          timeStamp,
          sign,
          fileCategory: this.$route.params.id,
          fileTitle: this.searchVal,
          pageNum: num,
        });
        console.log("文件", res.data);
        if (res.data.code == 200) {
          console.log(res.data);
          if (!this.flag) {
            this.matters.push(...res.data.rows);
            if (this.matters.length >= res.data.total) {
              this.finished = true;
            }
          } else this.matters = res.data.rows;
          // this.matters.sort((a, b) => a.fileIndex - b.fileIndex);
          this.pageNum = res.data.pageNum;
          this.pages = res.data.pages;
          this.total = res.data.total;
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async publishDate() {
      this.id = this.$route.params.id;
      console.log("id", this.$route.params.id);
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await getPublishDate({
          timeStamp,
          sign,
          contentType: this.$route.params.id,
        });
        console.log("时间", res.data);
        if (res.data.code == 200) {
          this.data = this.getStr(res.data.data, "T");
          console.log("sj", this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        // this.$message.error(error);
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@function px2rem($px, $screen: 1920) {
  @return calc(100vw / (#{$screen} / 10) * #{$px} / 10);
}

@function px2vw($px) {
  @return calc(100vw * #{$px} / 1920)
}
.mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  height: 100vh;
  width: 100vw;
  padding-bottom: px2rem(30, 375);

  header {
    width: 100%;
    height: px2rem(50, 375);
    display: flex;
    align-items: center;
    padding-left: px2rem(15, 375);
    img {
      width: px2rem(75, 375);
      height: px2rem(30, 375);
    }
  }

  .menus {
    width: 100%;
    height: px2rem(35, 375);
    background-color: rgba(0, 55, 129, 1);
    display: flex;
    align-items: center;
    font-size: px2rem(12, 375);
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    padding-left: px2rem(15, 375);
  }

  .main-content {
    width: 100%;
    padding: px2rem(25, 375) px2rem(20, 375);
    padding-bottom: px2rem(120, 375);
    height: 100%;
    overflow-y: scroll;

    .time {
      font-size: px2rem(12, 375);
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-bottom: px2rem(20, 375);
    }
    /deep/ .text-content {
      p {
        span {
          font-size: 16px !important;
        }
      }
    }

    .search {
      width: 100%;
      display: flex;
      justify-content: end;
      margin: px2rem(20, 375) 0;
      .search-wrap {
        width: 50%;
        display: flex;
        align-items: center;
      }

      /deep/ .el-input__inner {
        border: none !important;
        width: 100% !important;
        border-bottom: 1px solid !important;
        border-radius: 0 !important;
        height: px2rem(25, 375) !important;
      }

      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }

      img {
        width: px2rem(20, 375);
        height: px2rem(20, 375);
        margin-left: px2rem(10, 375);
        cursor: pointer;
      }
    }

    .list {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: px2rem(20, 275);
        a {
          text-decoration: none;
        }
        .title {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #003781;
          width: 70%;
        }
        .check-btn {
          width: px2rem(70, 375);
          height: px2rem(30, 375);
          background: #003781;
          display: flex;
          justify-content: center;
          align-items: center;

          a {
            font-size: px2rem(12, 375);
            font-family: PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }

    ::v-deep .van-list__finished-text {
      font-size: px2rem(12, 375);
      text-align: center;
    }
  }

  .bottom {
    width: 100%;
    background-color: #fff;
    padding: px2rem(10, 375) 0;
    position: absolute;
    bottom: 0;
    z-index: 9999;
    .qrcode {
      display: flex;
      justify-content: space-evenly;
      margin: px2rem(30, 375) 0;
      display: none;

      .item {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        img {
          width: px2rem(100, 375);
          height: px2rem(100, 375);
        }
        span {
          font-size: px2rem(13, 375);
          font-family: PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .info {
      p {
        font-size: px2rem(13, 375);
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        text-align: center;

        &.beian {
          color: RGBA(38, 77, 139, 1);
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.solvencyCapacity {
  width: 100%;

  > .box {
    width: 1034px;
    margin: 6rem auto;
    > .time {
      font-size: 2.2rem;
    }

    .text_cn {
      font-size: 2rem;
      margin-bottom: 3rem;
    }

    .text_en {
      font-size: 2rem;
    }

    .main {
      //width: 85%;
      //margin: auto;

      .box {
        //margin: 4rem 0;

        > .search {
          display: flex;
          align-items: center;
          justify-content: end;

          /deep/ .el-input__inner {
            border: none !important;
            width: 23rem !important;
            border-bottom: 1px solid !important;
            border-radius: 0 !important;
          }

          > img {
            width: 4rem;
            height: 4rem;
            margin-left: 1.5rem;
          }
        }

        .matters {
          > ul {
            > li {
              padding: 1.4rem 2rem;
              display: flex;
              font-size: 1.8rem;
              justify-content: space-between;
              align-items: center;
              //margin: 3rem 0;
              > a {
                width: 65%;
                text-decoration: none;
                > .matters_title {
                  font-size: 2.3rem;
                  color: #003781;
                }
              }

              > .matters_time {
              }

              > .matters_download {
                background-color: #003781;
                color: #fff;
                width: 15rem;
                height: 6rem;
                border-radius: 0.5rem;

                > a {
                  color: #fff;
                  text-decoration: none;
                  display: flex;
                  align-items: center;
                  justify-content: space-evenly;
                  border-radius: 0.5rem;
                  font-size: 1.8rem;
                  height: 100%;

                  > img {
                    width: 3rem;
                    height: 3rem;
                  }
                }
              }
            }
          }
        }

        .paging {
          width: 100%;
          padding: 0 2rem;
        }
      }
    }
  }
}
</style>
